import { PageType } from '@hotelplan/graphql.types';
import { heroCriteria, homeHeroCriteria } from 'components/domain/criterias';
import { useDynamicComponentsWithCriteria } from 'components/domain/dynamic-components/DynamicComponents.utils';
import { DynamicComponentFragment } from 'graphql/components/GetComponents.generated';

export const useComponents = (
  baseComponents: DynamicComponentFragment[] = [],
  pageType: PageType,
  path: string,
  id?: string
): DynamicComponentFragment[] => {
  const { data: componentsData } = useDynamicComponentsWithCriteria({
    variables: {
      input: { pageType, currentUri: path, id },
      heroCriteria:
        pageType === PageType.Home || pageType === PageType.Pdp
          ? homeHeroCriteria
          : heroCriteria,
    },
  });

  return componentsData?.components || baseComponents || [];
};
