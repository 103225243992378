import type { NextPage } from 'next';
import React from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { createLanguageURLsProps } from '@hotelplan/libs.language-urls';
import { apolloReqCtxUnitRef } from '@hotelplan/libs.req-ctx-api';
import { routeConfigUnitRef } from '@hotelplan/libs.route-config-api';
import { ServerSideUnit, ServerSideUnitRef } from '@hotelplan/libs.ssp-units';
import { getBaseComponents } from 'components/domain/components/components.util';
import { useComponents } from 'components/domain/components/useComponents';
import DynamicComponents from 'components/domain/dynamic-components/DynamicComponents';
import { StructuredDataOrganization } from 'components/domain/structured-data/StructuredDataOrganization';
import { createPageServerFn } from 'config/pageUnitsConfig';
import { DynamicComponentFragment } from 'graphql/components/GetComponents.generated';
import {
  GetHomeMetaDataDocument,
  GetHomeMetaDataQuery,
  GetHomeMetaDataQueryVariables,
} from 'graphql/home/GetHomeMetaData.generated';
import {
  GetOrganizationDocument,
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
} from 'graphql/organization/GetOrganization.generated';
import {
  GetFloatingRequestButtonDocument,
  GetFloatingRequestButtonQuery,
  GetFloatingRequestButtonQueryVariables,
} from 'graphql/requestButton/GetFloatingRequestButton.generated';

const HomePage: NextPage<{
  path: string;
  baseComponents?: DynamicComponentFragment[];
}> = ({ path, baseComponents }) => {
  const components = useComponents(baseComponents, PageType.Home, path);

  return (
    <>
      <DynamicComponents components={components} />
      <StructuredDataOrganization />
    </>
  );
};

const preloadHomeDataUnit = ServerSideUnit.createServerSideUnit(
  [apolloReqCtxUnitRef, routeConfigUnitRef],
  async (
    // deps
    ctx,
    { queryCtx },
    { searchParams }
  ) => {
    const path = searchParams.toString() ? `/?${searchParams}` : '/';

    const [
      { data: metaData = null },
      { data: baseComponents },
    ] = await Promise.all([
      queryCtx<GetHomeMetaDataQuery, GetHomeMetaDataQueryVariables>({
        query: GetHomeMetaDataDocument,
      }),
      getBaseComponents(queryCtx, PageType.Home, path),
      queryCtx<GetOrganizationQuery, GetOrganizationQueryVariables>({
        query: GetOrganizationDocument,
      }),
      queryCtx<
        GetFloatingRequestButtonQuery,
        GetFloatingRequestButtonQueryVariables
      >({
        query: GetFloatingRequestButtonDocument,
        variables: {
          pageType: PageType.Home,
        },
      }),
    ]);

    return {
      props: {
        meta: metaData.home.meta,
        path,
        baseComponents: baseComponents?.components,
      },
    };
  },
  new ServerSideUnitRef(`preload-home`)
);

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.Home,
    pageEventType: 'home',
    namespaceRequired: [`common`, `search`, `auth`],
    initialProps: createLanguageURLsProps({
      de: { uri: `/`, targetPageType: PageType.Home },
      fr: { uri: `/`, targetPageType: PageType.Home },
    }),
  },
  [preloadHomeDataUnit]
);

export default HomePage;
