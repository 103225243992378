import Head from 'next/head';
import React from 'react';
import type { Organization, WithContext } from 'schema-dts';
import {
  OrganizationFragment,
  useGetOrganizationQuery,
} from 'graphql/organization/GetOrganization.generated';

const structuredDataForOrganization = ({
  name,
  url,
  logo,
  phone,
  place,
  postalCode,
  region,
  street,
}: OrganizationFragment): WithContext<Organization> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name,
    url,
    logo,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: phone,
      contactType: `customer service`,
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: place,
      addressRegion: region,
      postalCode,
      streetAddress: street,
    },
  };
};

let StructuredDataOrganization: React.FC = () => null;

// structured data is not needed in browser
if (!process.browser) {
  // eslint-disable-next-line react/display-name
  StructuredDataOrganization = (): React.ReactElement => {
    const { data } = useGetOrganizationQuery({});

    if (!data?.organization) return null;

    return (
      <Head>
        <script
          key="org-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              structuredDataForOrganization(data.organization)
            ),
          }}
        />
      </Head>
    );
  };
}

export { StructuredDataOrganization };
